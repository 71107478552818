import React from 'react'
import { getAllReferences } from '../hooks/getAllReferences'
import { Reference } from './Reference'

export interface ReferenciesSectionProps {
	className?: string
}

export const References = (props: ReferenciesSectionProps) => {
	const { className } = props
	const references = getAllReferences()

	return (
		<div className={` ${className} `}>
			<h2 className="text-white text-center mb-10">Zmluvné poisťovne</h2>
			<div className="grid md:grid-cols-3 grid-cols-1 md:gap-x-8  z-10   w-full gap-y-6 md:gap-y-0">
				{references.nodes.map((reference, index: number) => (
					<Reference logo={reference.logo} name={reference.name} key={index} />
				))}
			</div>
		</div>
	)
}
