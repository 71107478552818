import React from 'react'
import { CONTACT } from '../../../lib/consts'

export interface AddressProps {
	className?: string
}

export const Address = (props: AddressProps) => {
	return (
		<div className={`mt-2 ${props.className}`}>
			<p className="text-lg font-semibold">Adresa</p>
			<p>
				<a
					href="https://goo.gl/maps/8dELyDh2jCCeNE5s5"
					className="hover:opacity-70 transition delay-150 duration-300 ease-in-out"
				>
					{CONTACT.address}
				</a>
			</p>
		</div>
	)
}
