import React from 'react'
import { Contact } from '../components/Contact/Contact'
import { Container } from '../components/Shared/Container/Container'
import Layout from '../components/Shared/Layout/Layout'
import { PageHeader } from '../components/Shared/PageHeader/PageHeader'
import { SEO } from '../components/Shared/SEO/SEO'

const ContactPage = () => (
	<Layout>
		<SEO
			description="V prípade akýchkoľvek otázok, či dohodnutia si termínu Vašej návštevy, nás neváhajte kontaktovať."
			title="Kontakt"
		/>
		<PageHeader
			pageTitle="Kontakt"
			pageIntroText="V prípade akýchkoľvek otázok, či dohodnutia si termínu Vašej návštevy, nás neváhajte kontaktovať."
		/>
		<Contact />
	</Layout>
)

export default ContactPage
