import { Link } from 'gatsby'
import React from 'react'
import { Button } from '../Button/Button'
import { Container } from '../Container/Container'
import { linearBg } from './pageHeader.module.scss'

interface PageHeaderProps {
	pageTitle: string
	pageIntroText: string
	buttonText?: string
	buttonUrl?: string
}
export const PageHeader = (props: PageHeaderProps) => {
	const { pageTitle, pageIntroText, buttonText, buttonUrl } = props
	return (
		<section className={`md:py-48 py-20 ${linearBg} `}>
			<Container>
				<div className="relative">
					{/* <div className="bg-gradient-blur w-48 h-48 rounded-full absolute -top-10 -left-10 z-0"></div> */}
					<h1>{pageTitle}</h1>
					<p className="mt-7 max-w-xl font-primary text-terciary leading-6">{pageIntroText}</p>
				</div>

				<div className="mt-12">
					{buttonText && buttonUrl && (
						<Link to={buttonUrl}>
							<Button>{buttonText}</Button>
						</Link>
					)}
				</div>
			</Container>
		</section>
	)
}
