import { graphql, useStaticQuery } from 'gatsby'
export const getAllReferences = () => {
	const { allStrapiReferences } = useStaticQuery(graphql`
		query allReferences {
			allStrapiReferences(limit: 10) {
				nodes {
					name
					logo {
						childImageSharp {
							gatsbyImageData(
								formats: WEBP
								placeholder: BLURRED
								layout: CONSTRAINED
								transformOptions: { cropFocus: CENTER }
								width: 250
							)
						}
					}
				}
			}
		}
	`)
	return allStrapiReferences
}
