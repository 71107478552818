import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from '../../Shared/Container/Container'
import { References } from './components/References'

export interface ReferencesSectionProps {
	className?: string
}

export const ReferencesSection = (props: ReferencesSectionProps) => {
	return (
		<section className="relative md:mt-12  mt-6 md:pt-20 pb-40 pt-10  mx-auto bg-primary">
			<div className=" rounded-tr-large z-0 md:block hidden"></div>

			<Container>
				<div className="flex relative justify-center">
					{/* <div className=" relative z-0 md:w-1/2 w-96 md:block hidden">
						<StaticImage
							src="../../../assets/images/references.jpg"
							alt={'name'}
							placeholder="blurred"
							layout="constrained"
							width={650}
							height={750}
							className="  bottom-0 -left-32 md:block hidden"
						/>
					</div> */}

					<References />
				</div>
			</Container>
		</section>
	)
}
