import React from 'react'
import { CONTACT } from '../../../lib/consts'

export interface WorkTimeProps {
	className?: string
}

export const WorkTime = (props: WorkTimeProps) => {
	return (
		<div className={`mt-2 ${props.className}`}>
			<p className="text-lg font-semibold">Ordinačné hodiny</p>
			<p className="">{CONTACT.workDays}</p>
			<p className="font-primary">
				<span className="text-primary font-bold">{CONTACT.worktime}</span>
			</p>
		</div>
	)
}
