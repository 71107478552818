import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { ReferencesSection } from '../Home/ReferencesSection/ReferencesSection'
import { Address } from '../Shared/Address/Address'
import { Container } from '../Shared/Container/Container'
import { TelNumber } from '../Shared/TelNumber/TelNumber'
import { WorkTime } from '../Shared/WorkTime/WorkTime'

export interface ContactProps {
	className?: string
}

export const Contact = (props: ContactProps) => {
	return (
		<section>
			<Container className="pb-20">
				<div className="">
					{/* <ContactMap /> */}
					<div className="flex md:space-x-4 w-full lg:space-x-12 space-x-0 flex-col md:flex-row  items-center">
						<div className="md:w-1/2 order-2 md:order-1 pt-5 md:pt-0">
							<div className="mt-6 md:mt-0">
								<StaticImage
									src="../../assets/images/ocna_ambulancia_banska_bystrica.jpg"
									alt="Očna ambulancia budova"
									title="Očna ambulancia budova"
									placeholder="blurred"
									layout="constrained"
									width={750}
									className="mx-auto block shadow-green"
								/>
							</div>
						</div>

						<div className="md:w-1/2 w-ful order-1 md:order-2 md:pl-16">
							<div className="mb-6">
								<WorkTime className="flex flex-col md:flex-row md:space-x-2 items-center" />
								<TelNumber className="flex flex-col md:flex-row md:space-x-6 items-center" />
								<Address className="flex flex-col md:flex-row md:space-x-6 items-center" />
							</div>

							<p className="font-bold text-center md:text-left">
								Parkovanie je možné priamo pred budovou alebo v OC Point (3 minúty chôdzou), tiež v
								Europa Shopping Center (6 minút chôdzou).
							</p>
							<p className="text-center md:text-left">
								V blízkosti sa nachádza aj autobusová zastávka –{' '}
								<span className="italic">Štadlerovo nábrežie</span>
							</p>
						</div>
					</div>
				</div>
			</Container>
			<ReferencesSection />
		</section>
	)
}
