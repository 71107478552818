import React from 'react'
import { CONTACT } from '../../../lib/consts'

export interface TelNumberProps {
	className?: string
}

export const TelNumber = (props: TelNumberProps) => {
	return (
		<div className={`mt-2 ${props.className}`}>
			<p className="text-lg font-semibold">Telefóne číslo</p>
			<p>
				<a
					href={`tel:${CONTACT.phone}`}
					className="hover:opacity-70 transition delay-150 duration-300 ease-in-out"
				>
					{CONTACT.phone}
				</a>
			</p>
		</div>
	)
}
