import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

export interface ReferenceProps {
	className?: string
	logo: IGatsbyImageData
	name: string
}

export const Reference = (props: ReferenceProps) => {
	const { name, logo } = props

	return (
		<div className=" flex justify-between flex-col shadow-md bg-white  rounded-tr-3xl rounded-bl-3xl px-8 py-8">
			<div className="mx-auto flex justify-center items-center">
				<GatsbyImage
					image={getImage(logo)}
					alt={`Zmluvná poisťovňa - ${name}`}
					placeholder="blurred"
					imgClassName=" object-center"
					className=" object-center"
				/>
			</div>
		</div>
	)
}
